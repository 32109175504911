<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p v-if="editMode" class="title">Edit Book</p>
        <p v-else class="title">Add Book</p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submitForm" id="editorForm">
      <div class="columns">
        <div class="column">
          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field label="Kids Content*">
                  <b-switch
                    v-model="formData.kidsContent"
                    type="is-success"
                  ></b-switch>
                </b-field>
                <b-field label="ISBN Number*">
                  <b-input v-model="formData.ISBN" required></b-input>
                </b-field>
                <b-field label="Title*">
                  <b-input v-model="formData.title" required></b-input>
                </b-field>
                <b-field label="Subject*">
                  <b-input v-model="formData.subject" required></b-input>
                </b-field>
                <b-field label="Description*">
                  <b-input v-model="formData.description" required></b-input>
                </b-field>
                <b-field
                  label="Keyword(s)*"
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input v-model="formData.keywords" required></b-input>
                </b-field>
                <b-field
                  label="Publication Year"
                  message="Enter year in the format YYYY e.g. 2021"
                >
                  <b-numberinput
                    v-model="formData.publicationYear"
                    :min="1000"
                    :max="9999"
                    :controls="false"
                    expanded
                    required
                  ></b-numberinput>
                </b-field>
                <b-field label="Subtitle">
                  <b-input v-model="formData.subTitle"></b-input>
                </b-field>
                <b-field label="Book Review">
                  <b-input
                    v-model="formData.bookReview"
                    type="textarea"
                  ></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Premium Content*">
                  <b-switch
                    v-model="formData.premiumContent"
                    type="is-success"
                  ></b-switch>
                </b-field>

                <b-field label="Accession Number*">
                  <b-input v-model="formData.accessionNo" required></b-input>
                </b-field>
                <b-field label="Call Number*">
                  <b-input v-model="formData.callNo" required></b-input>
                </b-field>
                <b-field label="Volume">
                  <b-input v-model="formData.volume"></b-input>
                </b-field>
                <b-field label="Pages">
                  <b-input v-model="formData.pages"></b-input>
                </b-field>

                <b-field
                  label="Author(s)*"
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input v-model="formData.authors" required></b-input>
                </b-field>

                <b-field label="Publisher*">
                  <b-input v-model="formData.publisher" required></b-input>
                </b-field>
                <b-field label="Place of Publication*">
                  <b-input
                    v-model="formData.placeOfPublication"
                    required
                  ></b-input>
                </b-field>
                <b-field label="Language">
                  <b-input v-model="formData.language"></b-input>
                </b-field>
              </div>
            </div>
            <hr />
            <div class="has-text-right">
              <b-button @click="cancel" class="button">Cancel</b-button>
              <button
                form="editorForm"
                class="button is-primary ml-4"
                type="submit"
                :disabled="isSubmitting"
              >
                {{ isSubmitting ? "Saving" : "Save" }}
              </button>
            </div>
          </div>
        </div>
        <div class="column is-4 is-narrow">
          <div v-if="formData.coverPhoto" class="box">
            <p class="pb-3 bold has-text-centered">
              <i class="mdi mdi-file-outline pr-1"></i>
              Current Uploads
            </p>
            <hr class="my-1" />
            <div v-if="formData.coverPhoto" class="mt-2">
              <div class="bold">Cover Photo</div>
              <div class="columns mt-1">
                <div class="column is-narrow pt-1">
                  <img :src="formData.coverPhoto" style="height: 100px" />
                </div>
                <div class="column has-text-right pt-1">
                  <a @click="deleteResource('cover')">
                    <b-tag type="is-danger is-light">
                      <i class="mdi mdi-close pr-1"></i> Delete
                    </b-tag>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!formData.coverPhoto" class="box">
            <b-field label="Cover Photo">
              <section>
                <b-field>
                  <b-upload v-model="coverPhotoFile" multiple drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p>Drop your files here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>

                <div class="tags">
                  <span
                    v-for="(file, index) in coverPhotoFile"
                    :key="index"
                    class="tag is-primary"
                  >
                    {{ file.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="deleteDropFile(index)"
                    ></button>
                  </span>
                </div>
              </section>
            </b-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "Editor",
  data() {
    return {
      isSubmitting: false,
      editMode: false,
      formData: {
        premiumContent: false,
        kidsContent: false,
        title: undefined,
        subject: undefined,
        description: undefined,
        keywords: undefined,
        authors: undefined,
        publicationYear: undefined,
        subTitle: undefined,
        ISBN: undefined,
        accessionNo: undefined,
        callNo: undefined,
        volume: undefined,
        pages: undefined,
        bookReview: undefined,
        publisher: undefined,
        placeOfPublication: undefined,
        language: undefined,
        coverPhoto: undefined
      },
      coverPhotoFile: []
    };
  },
  async created() {
    if (this.$route.params.itemDetail) {
      this.editMode = true;
      for (var key in this.$route.params.itemDetail) {
        if (this.$route.params.itemDetail[key]) {
          this.formData[key] = Array.isArray(this.$route.params.itemDetail[key])
            ? this.$route.params.itemDetail[key].join(", ")
            : this.$route.params.itemDetail[key];
        }
      }
    }
  },
  computed: {},
  methods: {
    deleteDropFile(index) {
      this.coverPhotoFile.splice(index, 1);
    },
    cancel() {
      this.$router.push({ name: "Books" });
    },

    async deleteResource(type) {
      const deleteResourceRequest = {
        resourceId: this.$route.params.itemDetail._id,
        resourceType: "PrintedBook",
        type: type
      };
      await this.$store
        .dispatch("resourceDelete", deleteResourceRequest)
        .then(() => {
          if (type == "cover") this.formData.coverPhoto = undefined;
        });
    },

    async submitForm() {
      this.isSubmitting = true;
      const request = cloneDeep(this.formData);
      request["keywords"] = request["keywords"].split(",").map(function(item) {
        return item.trim();
      });
      request["authors"] = request["authors"].split(",").map(function(item) {
        return item.trim();
      });
      if (this.editMode) {
        const uploadRequest = new FormData();
        uploadRequest.append("coverPhoto", this.coverPhotoFile[0]);
        uploadRequest.append("resourceId", this.$route.params.itemDetail._id);
        uploadRequest.append("resourceType", "PrintedBook");
        if (this.coverPhotoFile[0]) {
          await this.$store.dispatch("coverUpload", uploadRequest);
        }
        if (
          await this.$store.dispatch("editBook", {
            id: this.$route.params.itemDetail._id,
            request: request
          })
        ) {
          this.$router.push({ name: "Books" });
        }
      } else {
        const bookObj = await this.$store.dispatch("addBook", request);
        if (bookObj) {
          const uploadRequest = new FormData();
          uploadRequest.append("coverPhoto", this.coverPhotoFile[0]);
          uploadRequest.append("attachmentTitle", bookObj.title);
          uploadRequest.append("resourceId", bookObj._id);
          uploadRequest.append("resourceType", "PrintedBook");
          if (this.coverPhotoFile[0]) {
            await this.$store.dispatch("coverUpload", uploadRequest);
          }

          this.$router.push({ name: "Books" });
        }
      }
      this.isSubmitting = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
